import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import responseModal from '../../shared/responseModal';

import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
Highcharts3D(Highcharts);

export default {
  name: 'collectionDashboard',
  components: {
    DatePicker,
    responseModal
  },
  watch: {
    currentPage: function() {
      if(this.showOpenBankAccounts) {
        this.getBankAccounts();
      } else if(this.showOpenCustomers) {
        this.getCustomers();
      }
    },
    perPage: function() {
      this.currentPage = 1;
      if(this.showOpenBankAccounts) {
        this.getBankAccounts();
      } else if(this.showOpenCustomers) {
        this.getCustomers();
      }
    }
  },
  data() {
    return {
      loading: false,
      loader: false,
      showHideSearchFiltersModal: false,
      showValueSetModal: false,
      legalEntity: {
        value: '',
        text: ''
      },
      operatingUnit: {
        value: '',
        text: ''
      },
      defaultValue: {
        value: '',
        text: ''
      },
      bankAccounts: {
        value: '',
        text: ''
      },
      customers: {
        value: '',
        text: ''
      },
      currentPage: 1,
      glDateFrom: '',
      glDateTo: '',
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      showChartsFlag: false,
      collectionDashboardData: [],
      legalEntityData: [],
      legalEntityFields: [
        {
          key: 'legal_entity_id'
        },
        {
          key: 'name'
        }
      ],
      operatingUnitData: [],
      operatingUnitFields: [
        {
          key: 'name'
        }
      ],
      bankAccountsData: [],
      bankAccountsFields: [
        {
          key: 'bank_name'
        },
        {
          key: 'bank_branch_name'
        },
        {
          key: 'bank_account_num'
        },
        {
          key: 'bank_account_id'
        }
      ],
      customersData: [],
      customersFields: [
        {
          key: 'customer_name'
        },
        {
          key: 'customer_number'
        },
        {
          key: 'customer_id'
        }
      ],
      showOpenLegalEntity: false,
      showOpenOperatingUnit: false,
      showOpenBankAccounts: false,
      showOpenCustomers: false,
      displayDataFlag: false,
      currentDay: '',
      sevenDaysBefore: '',
      fifteenDaysBefore: '',
      thirtyDaysBefore: '',
      sevenDays: true,
      fifteenDays: false,
      thirtyDays: false,
      other: false,
      downloadpayload: {},

      showColumnCharts: false,
      minimizeFlag: true,
      reconcileTax: [],
      unreconcileTax: [],
      usernames: [],
      searchFilters: {},
      chartData: [
        {
          name: '',
          data: [],
          color: ''
        },
        {
          name: '',
          data: [],
          color: ''
        },
        {
          name: '',
          data: [],
          color: ''
        },
        {
          name: '',
          data: [],
          color: ''
        },
        {
          name: '',
          data: [],
          color: ''
        },
        {
          name: '',
          data: [],
          color: ''
        },
        {
          name: '',
          data: [],
          color: ''
        }
      ],
      showChartData: [],
      appliedData: [],
      miscData: [],
      onaccountData: [],
      refundData: [],
      unappliedData: [],
      unidentifiedData: [],
      writeoffData: [],
      date: [],
      allData: []
    };
  },
  mounted() {
    this.selectDays();
    this.getCollectionDashboard();
    this.showCharts();
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = { ...this.downloadPayload };
      this.downloadExcel.downloadData(
        downloadpayload,
        'collectionDashboard/getCollectionDashboard',
        'collection-dashboard',
        () => (this.loader = false),
        this.collectionDashboardData
      );
    },
    selectDays() {
      this.currentDay = this.getDateDaysAgo(0);
      this.sevenDaysBefore = this.getDateDaysAgo(6);
      this.fifteenDaysBefore = this.getDateDaysAgo(14);
      this.thirtyDaysBefore = this.getDateDaysAgo(29);
      this.glDateFrom = this.sevenDaysBefore;
      this.glDateTo = this.currentDay;
    },
    getCollectionDashboard() {
      this.collectionDashboardData = [];
      if (this.glDateFrom && this.glDateTo) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          org_id: this.operatingUnit.value,
          bank_account_id: this.bankAccounts.value,
          customer_id: this.customers.value,
          date_from: this.glDateFrom,
          date_to: this.glDateTo
        };
        this.downloadPayload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          org_id: this.operatingUnit.value,
          bank_account_id: this.bankAccounts.value,
          customer_id: this.customers.value,
          date_from: this.glDateFrom,
          date_to: this.glDateTo
        };
        this.loading = true;
        this.loader = true;
        this.appliedData = [];
        this.unappliedData = [];
        this.miscData = [];
        this.date = [];
        this.refundData = [];
        this.unidentifiedData = [];
        this.onaccountData = [];
        this.writeoffData = [];
        this.$store
          .dispatch('collectionDashboard/getCollectionDashboard', payload)
          .then(response => {
            if (response.status === 200) {
              this.displayDataFlag = true;
              this.collectionDashboardData = response.data.data.data.page;
              for (let i of this.collectionDashboardData) {
                this.appliedData.push(Math.trunc(+i.applied_amount / 100000));
                this.unappliedData.push(
                  Math.trunc(+i.unapplied_amount / 100000)
                );
                this.miscData.push(Math.trunc(+i.misclleneous_amount / 100000));
                this.date.push(i.receipt_date);
                this.refundData.push(Math.trunc(+i.refund_amount / 100000));
                this.unidentifiedData.push(
                  Math.trunc(+i.unidentified_amount / 100000)
                );
                this.onaccountData.push(
                  Math.trunc(+i.onaccount_amount / 100000)
                );
                this.writeoffData.push(Math.trunc(+i.writeoff_amount / 100000));
              }
              if (
                !(
                  this.glDateFrom === this.sevenDaysBefore ||
                  this.glDateFrom === this.fifteenDaysBefore ||
                  this.glDateFrom === this.thirtyDaysBefore
                )
              ) {
                this.filterDays('other', true);
              }
              this.chartData = [
                {
                  name: 'Applied',
                  data: this.appliedData,
                  color: '#0072B2'
                },
                {
                  name: 'Unapplied',
                  data: this.unappliedData,
                  color: '#D55E00'
                },
                {
                  name: 'Misc',
                  data: this.miscData,
                  color: '#009E73'
                },
                {
                  name: 'Refund',
                  data: this.refundData,
                  color: '#CC79A7'
                },
                {
                  name: 'Unidentified',
                  data: this.unidentifiedData,
                  color: '#F0E442'
                },
                {
                  name: 'WriteOff',
                  data: this.writeoffData,
                  color: 'purple'
                },
                {
                  name: 'OnAccount',
                  data: this.onaccountData,
                  color: 'pink'
                }
              ];
            }
            this.loading = false;
            this.loader = false;
            this.showCharts();
          })
          .catch(() => {
            this.loading = false;
            this.loader = false;
          });
      } else {
        alert('Please fill in the required fields!');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },
    openSearchFiltersModal() {
      this.showHideSearchFiltersModal = !this.showHideSearchFiltersModal;
      this.showCharts();
    },
    openValueSetModal(vsetCode, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.valueType = valueType;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.org_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ERP_LEGAL_ENTITIES) {
        this.legalEntity.value.push(item.erp_org_id);
        this.legalEntity.text.push(item.org_name);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CMS_OU) {
        this.operatingUnit = {
          value: item.org_id,
          text: item.org_name
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      }
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.operatingUnit = this.defaultValue;
      this.bankAccounts = this.defaultValue;
      this.customers = this.defaultValue;
      this.glDateFrom = '';
      this.glDateTo = '';
    },
    tempFunction() {
      this.showChartsFlag = true;
    },
    openCloseResponseModal(item) {
      if (item === 'Legal Entity') {
        this.showOpenLegalEntity = true;
        this.getLegalEntityData();
      } else if (item === 'Operating Unit') {
        if(this.legalEntity.value) {
          this.showOpenOperatingUnit = true;
          this.getOperatingUnitData();
        } else {
          alert("Please select Legal Entity");
        }  
      } else if (item === 'Bank Accounts') {
        this.showOpenBankAccounts = true;
        this.getBankAccounts();
      } else if (item === 'Customers') {
        this.showOpenCustomers = true;
        this.getCustomers();
      }
    },
    responseModal(flag) {
      this.showOpenLegalEntity = flag;
    },
    selectedLegalEntity(flag, legalEntity) {
      this.showOpenLegalEntity = flag;
      this.legalEntity = legalEntity;
    },
    selectedOperatingUnit(flag, operatingUnit) {
      this.showOpenOperatingUnit = flag;
      this.operatingUnit = operatingUnit;
    },
    selectedBankAccounts(flag, bankAccounts) {
      this.showOpenBankAccounts = flag;
      this.bankAccounts = bankAccounts;
    },
    selectedCustomers(flag, customers) {
      this.showOpenCustomers = flag;
      this.customers = customers;
    },
    getLegalEntityData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('collectionDashboard/getLegalEntity', payload)
        .then(response => {
          if (response.status === 200) {
            this.legalEntityData = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOperatingUnitData() {
      const payload = {
        default_legal_context_id: this.legalEntity.value,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('collectionDashboard/getOperatingUnit', payload)
        .then(response => {
          if (response.status === 200) {
            this.operatingUnitData = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getBankAccounts() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('collectionDashboard/getBankACcounts', payload)
        .then(response => {
          if (response.status === 200) {
            this.bankAccountsData = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCustomers() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('collectionDashboard/getCustomers', payload)
        .then(response => {
          if (response.status === 200) {
            this.customersData = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
        const payload1 = {
          _page: this.currentPage - 1,
          _limit: 10000
        };
        this.loading = true;
        this.$store
          .dispatch('collectionDashboard/getCustomers', payload1)
          .then(response => {
            if (response.status === 200) {
              this.allData = response.data.data.data.page;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    legalEntityModal(flag) {
      this.showOpenLegalEntity = flag;
    },
    operatingUnitModal(flag) {
      this.showOpenOperatingUnit = flag;
    },
    bankAccountsModal(flag) {
      this.showOpenBankAccounts = flag;
    },
    customersModal(flag) {
      this.showOpenCustomers = flag;
    },
    clearSearchParams(params) {
      if (params === 'Legal Entity') {
        this.legalEntity = this.defaultValue;
      } else if (params === 'Operating Unit') {
        this.operatingUnit = this.defaultValue;
      } else if (params === 'Bank Accounts') {
        this.bankAccounts = this.defaultValue;
      } else if (params === 'Customers') {
        this.customers = this.defaultValue;
      }
    },
    getDateDaysAgo(daysAgo) {
      const currentDate = new Date();
      const targetDate = new Date(currentDate);
      targetDate.setDate(currentDate.getDate() - daysAgo);

      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];

      const day = String(targetDate.getDate()).padStart(2, '0');
      const month = months[targetDate.getMonth()];
      const year = targetDate.getFullYear();

      return `${day}-${month}-${year}`;
    },
    addDaysToDate(dateString, days) {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      const parts = dateString.split('-');
      const day = parseInt(parts[0], 10);
      const month = months.indexOf(parts[1]);
      const year = parseInt(parts[2], 10);
      const date = new Date(year, month, day);
      date.setDate(date.getDate() + days);
      const resultDay = String(date.getDate()).padStart(2, '0');
      const resultMonth = months[date.getMonth()];
      const resultYear = date.getFullYear();

      return `${resultDay}-${resultMonth}-${resultYear}`;
    },
    disabledDates(date) {
      const tempDate = this.addDaysToDate(this.glDateFrom, 29);
      const restrictedDate = new Date(tempDate);
      restrictedDate.setHours(0, 0, 0, 0);
      return date > restrictedDate;
    },
    filterDays(days, flag) {
      if(flag) {
      if (days === 'seven') {
        this.fifteenDays = false;
        this.thirtyDays = false;
        this.other = false;
        this.glDateFrom = this.sevenDaysBefore;
        this.glDateTo = this.currentDay;
        this.getCollectionDashboard();
      } else if (days === 'fifteen') {
        this.sevenDays = false;
        this.thirtyDays = false;
        this.other = false;
        this.glDateFrom = this.fifteenDaysBefore;
        this.glDateTo = this.currentDay;
        this.getCollectionDashboard();
      } else if (days === 'thirty') {
        this.fifteenDays = false;
        this.sevenDays = false;
        this.other = false;
        this.glDateFrom = this.thirtyDaysBefore;
        this.glDateTo = this.currentDay;
        this.getCollectionDashboard();
      } else if (days === 'other') {
        this.fifteenDays = false;
        this.sevenDays = false;
        this.thirtyDays = false;
        this.other = true;
      }
    }
    },

    showCharts() {
      const chart = Highcharts.chart('container', {
        chart: {
          type: 'column',
          style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '16px'
          },
          height: 470,
          width: this.showHideSearchFiltersModal ? 1010 : 1350
        },
        credits: {
          enabled: false
        },
        title: {
          text: '',
          align: 'left'
        },
        xAxis: {
          categories: this.date,
          title: {
            text: 'Date',
            style: {
              fontWeight: '400',
              fontSize: '20px',
              color: 'white'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Amount (₹ Lakhs)',
            style: {
              fontWeight: '400',
              fontSize: '20px',
              color: 'white'
            }
          },
          stackLabels: {
            enabled: true
          }
        },
        legend: {
          align: 'left',
          x: 270,
          verticalAlign: 'top',
          y: -10,
          floating: true,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false,
          style: {
            textOutline: 'none'
          }
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat:
            '{series.name}: ₹{point.y} Lakhs<br/>Total: ₹{point.stackTotal} Lakhs'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            },
            shadow: false,
            style: {
              textOutline: 'none'
            }
          }
        },
        series: this.chartData
      });
      if (this.chartData[0].data.length === 0) {
        const renderer = chart.renderer;
        renderer
          .text('There is no data for the selected fields', 400, 220)
          .css({
            color: 'white',
            fontSize: '24px',
            fontWeight: '400'
          })
          .attr({
            zIndex: 5
          })
          .add();
      }
    }
  }
};